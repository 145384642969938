import { GetterTree } from "vuex"

import { RootState } from "@/store"

import { State } from "./state"
import { Pagination, IMeasureDetail } from "@/types"
import { IMeasureOverview } from "@/types"

export type Getters = {
  getMeasureOverview(state: State): IMeasureOverview[]
  getMeasureDetail(state: State): IMeasureDetail | null
  getMeasurePagination(state: State): Pagination
  getMeasureSearchValue(state: State): string
}

export const getters: GetterTree<State, RootState> & Getters = {
  getMeasureOverview: (state) => state.measureOverview,
  getMeasureDetail: (state) => state.measureDetail,
  getMeasurePagination: (state) =>
    state.pagination === null ? new Pagination(1, 10) : state.pagination,
  getMeasureSearchValue: (state) => state.searchValue || "",
}
