import { CurrencyFormatter as currencyFormatter } from "@/helpers"
export interface IMeasureBonus {
  id: string
  sponsorshipSupportAmountFrom: number
  sponsorshipSupportAmountTo: number
  unit: string
  description: string
}

export function getMeasureBonusItemOutput(
  bonus: IMeasureBonus,
  shortOutput = false
): string {
  let res = ""

  if (
    bonus.sponsorshipSupportAmountFrom &&
    bonus.sponsorshipSupportAmountTo &&
    !shortOutput
  ) {
    res +=
      currencyFormatter.format(bonus.sponsorshipSupportAmountFrom) +
      " - " +
      currencyFormatter.format(bonus.sponsorshipSupportAmountTo)
  }

  if (
    bonus.sponsorshipSupportAmountFrom &&
    (!bonus.sponsorshipSupportAmountTo || shortOutput)
  ) {
    const prefix = shortOutput ? "" : "ab "
    res += prefix + currencyFormatter.format(bonus.sponsorshipSupportAmountFrom)
  }

  if (!bonus.sponsorshipSupportAmountFrom && bonus.sponsorshipSupportAmountTo) {
    const prefix = shortOutput ? "" : "bis "
    res += prefix + currencyFormatter.format(bonus.sponsorshipSupportAmountTo)
  }

  if (bonus.unit) {
    if (shortOutput) {
      res += "/" + bonus.unit
    } else {
      res += " | je " + bonus.unit
    }
  }

  if (bonus.description && shortOutput == false) {
    res += " | " + bonus.description
  }

  return res
}
