import { MutationTree } from "vuex"
import { State } from "./state"
import { UserMutationTypes } from "./mutation-types"
import { IUserProfile } from "@/types"

export type Mutations<S = State> = {
  [UserMutationTypes.SET_USER_PROFILE](
    state: S,
    userProfile: IUserProfile | null
  ): void
}

export const mutations: MutationTree<State> & Mutations = {
  [UserMutationTypes.SET_USER_PROFILE](
    state: State,
    userProfile: IUserProfile | null
  ) {
    state.userProfile = userProfile
  },
}
