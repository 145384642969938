/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, ActionContext } from "vuex"
import { RootState } from "@/store"
import { State } from "./state"
import { Mutations } from "./mutations"
import { UserMutationTypes } from "./mutation-types"
import { UserActionTypes } from "./action-types"
import { IUserProfile } from "@/types"
import { Pagination } from "@/types/pagination/Pagination"
import { IStateFilterItem } from "@/types/filter/IStateFilterItem"
import { FilterActionTypes } from "@/store/modules/filter/action-types"
import { MeasureActionTypes } from "@/store/modules/measure/action-types"
import { UserDataService } from "@/api/services"
import { useStore } from "vuex"
import { computed } from "vue"

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, "commit">
export interface Actions {
  [UserActionTypes.SET_USER_PROFILE](
    { commit, getters, dispatch }: AugmentedActionContext,
    payload: IUserProfile | null
  ): Promise<boolean>
}

export const actions: ActionTree<State, RootState> & Actions = {
  [UserActionTypes.SET_USER_PROFILE]({ commit, getters, dispatch }, payload) {
    return new Promise(() => {
      commit(UserMutationTypes.SET_USER_PROFILE, payload)
      dispatch(UserActionTypes.SET_USER_FILTER)
    })
  },

  [UserActionTypes.SAVE_USER_FILTER]({ commit, getters, dispatch }, payload) {
    return new Promise(() => {
      const store = useStore()
      const userProfile = getters.getUserProfile
      if (userProfile && userProfile.id) {
        UserDataService.setUserFilter(userProfile.id, payload)
      }
    })
  },

  [UserActionTypes.SET_USER_FILTER]({ commit, getters, dispatch }, payload) {
    return new Promise(() => {
      const store = useStore()
      const userProfile = getters.getUserProfile

      if (!userProfile || !userProfile.id) {
        return
      }

      UserDataService.getUserFilter(userProfile.id).then(
        (storedFilterItems) => {
          const statesFilter = getters.getFilterById("statesFilter")
          const activeStates = statesFilter?.items.filter(
            (state: IStateFilterItem) => {
              return state.isActive
            }
          )

          if (activeStates && !activeStates.length) {
            const mappedItems = statesFilter.items.filter(
              (state: IStateFilterItem) => {
                return (storedFilterItems as Array<any>).find(
                  (storedItem: any) => {
                    return storedItem.id === state.id && storedItem.isActive
                  }
                )
              }
            )

            mappedItems.forEach((state: IStateFilterItem) => {
              state.isActive = true
              dispatch(FilterActionTypes.UPDATE_FILTER_ITEM, {
                item: state,
                filter: "statesFilter",
              })
            })
            dispatch(FilterActionTypes.GET_MEASURE_FILTER).then(
              (res: boolean) => {
                const paginationSetup = getters.getMeasurePagination
                dispatch(
                  MeasureActionTypes.UPDATE_MEASURE_PAGINATION,
                  new Pagination(1, paginationSetup.limit)
                )
                dispatch(MeasureActionTypes.GET_MEASURES_OVERVIEW)
              }
            )
          }
        }
      )
    })
  },
}
