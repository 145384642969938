import { ICheckboxFilter } from "./ICheckboxFilter"
import { IDimensionsFilter } from "./IDimensionsFilter"
import { ITemporallyFilter } from "./ITemporallyFilter"

export class MeasureOverviewFilter {
  supportProgramsFilter?: ICheckboxFilter
  temporallyFilter?: ITemporallyFilter
  statesFilter?: ICheckboxFilter
  measureTypesFilter?: ICheckboxFilter
  // locationsFilter?: ICheckboxFilter
  targetAreaFilter?: ICheckboxFilter
  formsFilter?: ICheckboxFilter
  dimensionsFilter?: IDimensionsFilter
  // measureBonusFilter?: ICheckboxFilter // Temp
  commitmentTimesFilter?: ICheckboxFilter
  sceneFilter?: ICheckboxFilter;

  [key: string]:
    | ICheckboxFilter
    | ITemporallyFilter
    | IDimensionsFilter
    | undefined

  constructor(
    supportProgramsFilter?: ICheckboxFilter,
    temporallyFilter?: ITemporallyFilter,
    statesFilter?: ICheckboxFilter,
    targetAreasFilter?: ICheckboxFilter,
    // locationsFilter?: ICheckboxFilter,
    targetAreaFilter?: ICheckboxFilter,
    formFilter?: ICheckboxFilter,
    dimensionsFilter?: IDimensionsFilter,
    // measureBonusFilter?: ICheckboxFilter,
    commitmentTimesFilter?: ICheckboxFilter,
    sceneFilter?: ICheckboxFilter
  ) {
    this.statesFilter = statesFilter
    this.supportProgramsFilter = supportProgramsFilter
    this.temporallyFilter = temporallyFilter
    this.targetAreaFilter = targetAreaFilter
    this.measureTypesFilter = targetAreasFilter
    this.formsFilter = formFilter
    // this.locationsFilter = locationsFilter
    this.dimensionsFilter = dimensionsFilter
    // this.measureBonusFilter = measureBonusFilter
    this.commitmentTimesFilter = commitmentTimesFilter
    this.sceneFilter = sceneFilter
  }
}
