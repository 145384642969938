import { createApp, h } from "vue"
import App from "./App.vue"
import router from "./router"
import { store } from "@/store"
import "bootstrap" // Bootstrap Javascript
import Keycloak from "keycloak-js"
import { FilterActionTypes } from "./store/modules/filter/action-types"
import { MeasureActionTypes } from "./store/modules/measure/action-types"
import { UserActionTypes } from "@/store/modules/user/action-types"

const app = createApp({
  setup() {
    // const application = getCurrentInstance()
    // const keycloak = application?.appContext.config.globalProperties.keycloak
    // keycloak.init().then((isAuthenticated: any) => {
    //   console.log("isAuthenticated", isAuthenticated)
    // })

    const keycloak = Keycloak({
      url: "https://auth-keycloak01.flynet.de/auth",
      realm: "Natapp",
      clientId: "infothek",
      // url: "https://keycloak-dev.goe.flynet.de/auth",
      // realm: "Natapp",
      // clientId: "infothek",
    })
    keycloak
      .init({
        onLoad: "check-sso",
        // silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso",
      })
      .then((isAuthenticated) => {
        console.log("isAuthenticated", isAuthenticated)
        store.dispatch(UserActionTypes.SET_USER_PROFILE, null)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        store
          .dispatch(FilterActionTypes.GET_MEASURE_FILTER)
          .then(() => {
            store.dispatch(MeasureActionTypes.GET_MEASURES_OVERVIEW)
          })
          .catch((error) => {
            console.log("error", error)
            store.dispatch(UserActionTypes.SET_USER_PROFILE, null)
          })
      })
    app.config.globalProperties.keycloak = keycloak
  },
  render: () => h(App),
})

app.use(store)
app.use(router)
app.mount("#app")
