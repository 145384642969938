
import { defineComponent } from "vue"
import { version } from "../../package.json"

export default defineComponent({
  name: "Footer",
  setup() {
    return {
      version: version,
    }
  },
  computed: {
    year() {
      return new Date().getFullYear()
    },
  },
})
