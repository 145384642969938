import { IInterval } from "./IInterval"

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IDate {
  id: string
  from?: Date
  until?: Date
  interval?: IInterval
  description?: string
  from_description?: string
  until_description?: string
  internalName?: string
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createDateRangeItem(apiData: any): IDate {
  let interval: IInterval | undefined
  if (apiData.interval) {
    if (apiData.interval.id) {
      interval = {
        id: apiData.interval.id,
        value: apiData.interval.value,
        label: apiData.interval.label,
      }
    }
  }
  return {
    id: apiData.id,
    internalName: apiData.internalName,
    from: apiData.from === "0000-00-00 00:00:00" ? undefined : apiData.from,
    until: apiData.until === "0000-00-00 00:00:00" ? undefined : apiData.until,
    interval: interval,
    description: apiData.description,
    from_description: apiData.from_description,
    until_description: apiData.until_description,
    // getFromUntilDescription: function() {
    //   return "Test"
    // }
  }
}
