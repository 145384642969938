import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "icons d-flex position-absolute end-0 top-50 translate-middle-y" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
      type: "text",
      placeholder: "Maßnahme suchen",
      "aria-label": "Search",
      class: "search shadow-0 bg-gray-200 form-control-sm form-control rounded-pill py-2 ps-3 pe-5 lh-0",
      onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onKeyup && _ctx.onKeyup(...args)))
    }, null, 544), [
      [_vModelText, _ctx.searchValue]
    ]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.searchValue.length)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.resetSearchValue && _ctx.resetSearchValue(...args))),
            "aria-hidden": "true",
            class: "btn btn-link me-1 flycon flycon-top flycon-16 flycon-close text-dark lh-0 rounded-circle p-1 border-0"
          }, " ­ "))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        "aria-hidden": "true",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.performSearch && _ctx.performSearch(...args))),
        class: _normalizeClass(["btn-primary me-1 flycon flycon-top flycon-16 flycon-search-alt text-light bg-primary lh-0 rounded-circle p-1 border-0", { 'opacity-50': _ctx.searchValue.length === 0 }]),
        disabled: _ctx.searchValue.length === 0
      }, " ­ ", 10, _hoisted_2)
    ])
  ], 64))
}