import { apiClient } from "@/api/common"
import { Pagination } from "@/types"
import { AxiosResponse } from "axios"

export class MeasureDataService {
  static getOverview(
    pagination: Pagination,
    searchValue?: string,
    filterValue?: string
  ): Promise<AxiosResponse> {
    const params = {
      limit: pagination.limit || 10,
      page: pagination.actPage,
      search: searchValue,
      filter: filterValue,
    }
    if (!params.search) {
      delete params.search
    }

    if (!params.filter) {
      delete params.filter
    }

    return apiClient.get("/collection/measure", {
      // params: { limit: pagination.limit || 10, page: pagination.actPage },
      params: params,
    })
  }
  static getMeasure(id: string): Promise<AxiosResponse> {
    return apiClient.get(`/collection/measure/${id}`)
  }
  static getAll(
    pagination: Pagination,
    searchValue?: string
  ): Promise<AxiosResponse> {
    const params = {
      limit: pagination.limit || 10,
      page: pagination.actPage,
      search: searchValue,
    }
    if (!params.search) {
      delete params.search
    }

    return apiClient.get("/collection/massnahmen", {
      // params: { limit: pagination.limit || 10, page: pagination.actPage },
      params: params,
    })
  }

  static getBy(id: string): Promise<AxiosResponse> {
    return apiClient.get(`/collection/massnahmen/${id}`)
  }

  static searchBy(nameOrCode: string): Promise<AxiosResponse> {
    return apiClient.get(`/collection/massnahmen/search/${nameOrCode}`)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getFilter(stateIds: string): Promise<AxiosResponse> {
    // TODO temp
    // const values = { values: ["12373D1B-60369898", "12373D1B-B54D8E2D"] }
    // const p = { states: JSON.stringify(values) }
    const p =
      stateIds !== ""
        ? {
            states: stateIds,
          }
        : {}

    return apiClient.get("/collection/filter", { params: p })
  }
}
