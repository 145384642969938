export class Pagination {
  self?: string
  first?: string
  last?: string
  next?: string
  previous?: string
  totalPages?: number
  totalItems?: number
  actPage? = 1
  private _limit = 3

  constructor(actPage?: number, limit?: number) {
    this.actPage = actPage
    this._limit = limit || 10
  }

  public set limit(value: number) {
    this._limit = value || 10
    this.actPage = 1
  }

  public get limit(): number {
    return this._limit || 10
  }
}
