import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12f86a23"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-overlay-inner" }
const _hoisted_2 = { class: "search-overlay-inner-header d-flex align-items-center justify-content-center px-3 py-1 border-bottom" }
const _hoisted_3 = { class: "search-wrapper position-relative d-flex flex-grow-1" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "icons d-flex" }
const _hoisted_6 = {
  key: 0,
  class: "search-overlay-inner-body"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "list-unstyled mb-0 px-3 pt-1" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "row gx-0 border-bottom pb-2 mb-n2" }
const _hoisted_11 = { class: "col-12" }
const _hoisted_12 = { class: "content-left d-flex align-items-center" }
const _hoisted_13 = { class: "mb-0 fw-medium fs-4" }
const _hoisted_14 = { class: "mb-0 fw-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchEmptyState = _resolveComponent("SearchEmptyState")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          "aria-hidden": "true",
          class: _normalizeClass(["btn flycon flycon-top flycon-20 flycon-search text-dark rounded-circle me-1 ms-1 p-1 ps-1 lh-0 border-0 position-absolute top-50 translate-middle-y", { 'opacity-50': _ctx.searchValue.length === 0 }]),
          disabled: _ctx.searchValue.length === 0
        }, " ­ ", 10, _hoisted_4),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
          type: "text",
          placeholder: "Maßnahme suchen",
          "aria-label": "Search",
          class: "search shadow-0 bg-gray-200 form-control-sm form-control rounded-pill py-2 ps-5 pe-5 lh-0 shadow-none",
          onInput: _cache[1] || (_cache[1] = ($event: any) => (
            _ctx.searchValue === ''
              ? (_ctx.toggleOverlayBody = false)
              : (_ctx.toggleOverlayBody = true),
              _ctx.performSearch()
          )),
          autocomplete: "off"
        }, null, 544), [
          [_vModelText, _ctx.searchValue]
        ]),
        (_ctx.searchValue.length)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.resetSearchValue && _ctx.resetSearchValue(...args))),
              "aria-hidden": "true",
              class: "btn flycon flycon-top flycon-20 flycon-close text-dark rounded-circle lh-0 me-1 ms-1 p-1 ps-1 border-0 position-absolute top-50 translate-middle-y end-0"
            }, " ­ "))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("a", {
          href: "#0",
          class: "text-decoration-underline link-dark fw-medium d-sm-block fs-5",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('toggleEvent', false), _ctx.resetSearchValue()))
        }, " Abbrechen ")
      ])
    ]),
    (_ctx.toggleOverlayBody)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (!_ctx.measures.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_Transition, {
                  name: "fade",
                  mode: "out-in"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_SearchEmptyState, { wrapperClass: 'p-2' })
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("ul", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.measures, (measure, index) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "card flex-column rounded-0 border-0 ms-n2 me-n2 bg-transparent rounded-2",
                key: index
              }, [
                _createElementVNode("div", {
                  class: "card-body p-2",
                  onClick: ($event: any) => (_ctx.navigateTo(measure.id, measure.label))
                }, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", null, [
                          _createElementVNode("h3", _hoisted_13, _toDisplayString(measure.label), 1),
                          _createElementVNode("p", _hoisted_14, _toDisplayString(measure.measureType.label), 1)
                        ])
                      ])
                    ])
                  ])
                ], 8, _hoisted_9)
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}