/* eslint-disable @typescript-eslint/no-unused-vars */
import { MeasureActionTypes } from "@/store/modules/measure/action-types"
import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router"
import { useStore } from "vuex"
import { store } from "../store"
// const store = useStore()
const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  {
    path: "/",
    name: "MeasuresOverview",
    component: () => import("../views/MeasuresOverview.vue"),
  },
  {
    path: "/measure/:label-:id",
    name: "MeasuresDetail",
    props: true,
    component: () => import("../views/MeasuresDetail.vue"),
    beforeEnter(to, from, next) {
      store
        .dispatch(MeasureActionTypes.GET_MEASURE, to.params.id)
        .then((success) => {
          if (success) {
            next()
          }
        })
    },
  },
  {
    path: "/datenschutz",
    name: "Privacy",
    component: () => import("../components/legal/Privacy.vue"),
  },
  {
    path: "/impressum",
    name: "Imprint",
    component: () => import("../components/legal/Imprint.vue"),
  },
  {
    path: "/kontakt",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  // {
  //   path: "/silent-check-sso",
  //   name: "SilentCheckSso",
  //   component: () => import("../views/SilentCheckSso.vue"),
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    return { left: 0, top: 0 }
  },
})

export default router
