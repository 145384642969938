export interface IResource {
  id: string
  internalName: string
  label: string
  link: string
  type: string
}

export function getFormatedResourceType(resource: IResource): string {
  switch (resource.type) {
    case "image/pjpeg":
    case "image/jpeg":
      return "JPG-Datei"
    case "image/png":
      return "PNG-Datei"
    case "application/pdf":
      return "PDF-Datei"
    case "url":
      return "URL"
    default:
      return "unknown type"
  }
}
