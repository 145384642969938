import { apiClient } from "@/api/common"
import { AxiosResponse } from "axios"

type MyResponseDataItem = {
  id: string,
  isActive: boolean
}

type MyresponseData = {
  filter: {
    items: MyResponseDataItem[]
    filter: string
  },
  success: boolean
}

export abstract class UserDataService {
  static setUserFilter(userid: string , filter: string): Promise<AxiosResponse> {
    return apiClient.post("/user/filter", { 
      userid: userid,
      filter: filter,
    })
  }

  static getUserFilter(userid: string) {
    return apiClient.get("/user/filter", { params: {
        userid: userid,
      }
    }).then ( function (response: AxiosResponse) {
      const responseData: MyresponseData = response.data as MyresponseData
      return responseData.filter.items || []
    })
    .catch ( function (error){
        console.log("ajax getUserFilter error")
        console.log(error)
    })
  }
}
