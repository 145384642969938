import { MutationTree } from "vuex"
import { State } from "./state"
import { FilterMutationTypes } from "./mutation-types"
import { MeasureOverviewFilter, ICheckboxFilterItem } from "@/types"
import { ICheckboxFilter } from "@/types/filter/ICheckboxFilter"

export type Mutations<S = State> = {
  [FilterMutationTypes.SET_MEASURE_FILTER](
    state: S,
    measureOverviewFilter: MeasureOverviewFilter
  ): void
  [FilterMutationTypes.SET_FILTER_ITEM](
    state: S,
    payload: { item: { id: string; isActive: boolean }; filter: string }
  ): void
  [FilterMutationTypes.SET_FILTER_ITEMS](
    state: S,
    payload: { items: [{ id: string; isActive: boolean }]; filter: string }
  ): void
  [FilterMutationTypes.SET_TEMPORALLY_FILTER](
    state: S,
    payload: { filterValue: { from: string; to: string } }
  ): void
  [FilterMutationTypes.SET_WIDTH_FILTER](
    state: S,
    payload: { filterValue: { min: number; max: number } }
  ): void
  [FilterMutationTypes.SET_HECTARE_FILTER](
    state: S,
    payload: { filterValue: { min: number; max: number } }
  ): void
}

export const mutations: MutationTree<State> & Mutations = {
  [FilterMutationTypes.SET_MEASURE_FILTER](
    state: State,
    measureOverviewFilter: MeasureOverviewFilter
  ) {
    state.measureOverviewFilter = measureOverviewFilter
  },
  [FilterMutationTypes.SET_FILTER_ITEM](
    state: State,
    payload: { item: { id: string; isActive: boolean }; filter: string }
  ) {
    const filter = state.measureOverviewFilter
    let targetFilter = null
    if (filter) {
      targetFilter = filter[payload.filter] as ICheckboxFilter
    }

    if (targetFilter) {
      const item = targetFilter?.items?.find((el: ICheckboxFilterItem) => {
        return el.id === payload.item.id
      })

      if (item) {
        item.isActive = payload.item.isActive
      }
    }
  },
  [FilterMutationTypes.SET_FILTER_ITEMS](
    state: State,
    payload: { items: [{ id: string; isActive: boolean }]; filter: string }
  ) {
    const filter = state.measureOverviewFilter
    let targetFilter = null
    if (filter) {
      targetFilter = filter[payload.filter] as ICheckboxFilter
    }

    if (targetFilter) {
      targetFilter.items?.forEach((item: ICheckboxFilterItem) => {
        const payloadItem = payload.items.find((el) => {
          return el.id === item.id
        })
        item.isActive = payloadItem?.isActive || false
      })
    }
  },
  [FilterMutationTypes.SET_TEMPORALLY_FILTER](
    state: State,
    payload: { filterValue: { from: string; to: string } }
  ) {
    if (state.measureOverviewFilter?.temporallyFilter) {
      state.measureOverviewFilter.temporallyFilter.from =
        payload.filterValue.from
      state.measureOverviewFilter.temporallyFilter.to = payload.filterValue.to
    }
  },
  [FilterMutationTypes.SET_WIDTH_FILTER](
    state: State,
    payload: { filterValue: { min: number; max: number } }
  ) {
    if (state.measureOverviewFilter?.dimensionsFilter?.widthFilter) {
      state.measureOverviewFilter.dimensionsFilter.widthFilter.min =
        payload.filterValue.min
      state.measureOverviewFilter.dimensionsFilter.widthFilter.max =
        payload.filterValue.max
    }
  },
  [FilterMutationTypes.SET_HECTARE_FILTER](
    state: State,
    payload: { filterValue: { min: number; max: number } }
  ) {
    if (state.measureOverviewFilter?.dimensionsFilter?.hectareFilter) {
      state.measureOverviewFilter.dimensionsFilter.hectareFilter.min =
        payload.filterValue.min
      state.measureOverviewFilter.dimensionsFilter.hectareFilter.max =
        payload.filterValue.max
    }
  },
}
