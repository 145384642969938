/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, ActionContext } from "vuex"

import { RootState } from "@/store"

import { State } from "./state"
import { Mutations } from "./mutations"
import { MeasureMutationTypes } from "./mutation-types"
import { MeasureActionTypes } from "./action-types"
import { ResponseData } from "@/types/ResponseData"
import { MeasureDataService } from "@/api/services"
import { createMeasureOverviewItem, IMeasureOverview } from "@/types"
import { Pagination } from "@/types/pagination/Pagination"
import { createMeasureDetailItem } from "@/types"

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, "commit">

export interface Actions {
  [MeasureActionTypes.GET_MEASURES_OVERVIEW]({
    commit,
  }: AugmentedActionContext): Promise<boolean>

  [MeasureActionTypes.GET_MEASURE](
    { commit }: AugmentedActionContext,
    id: string
  ): Promise<boolean>
  [MeasureActionTypes.UPDATE_MEASURE_PAGINATION](
    { commit }: AugmentedActionContext,
    pagination: Pagination
  ): boolean
  [MeasureActionTypes.UPDATE_SEARCH_VALUE](
    { commit }: AugmentedActionContext,
    value: string
  ): boolean
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [MeasureActionTypes.GET_MEASURES_OVERVIEW]({
    commit,
    dispatch,
    getters,
  }) {
    return new Promise(() => {
      const paginationSetup = getters.getMeasurePagination
      const searchValue = getters.getMeasureSearchValue
      const filter = getters.getApiFilterParams
      dispatch(MeasureActionTypes.UPDATE_MEASURE_PAGINATION, paginationSetup)
      MeasureDataService.getOverview(paginationSetup, searchValue, filter)
        .then((response: ResponseData) => {
          const data: Array<any> = response.data.data
          const paginationMeta: any = response.data._meta
          const paginationLinks: any = response.data._links
          const updatedPagination = new Pagination(
            paginationMeta.actPage,
            paginationSetup.limit
          )
          updatedPagination.self = paginationLinks.self
          updatedPagination.first = paginationLinks.first
          updatedPagination.last = paginationLinks.last
          updatedPagination.next = paginationLinks.next
          updatedPagination.previous = paginationLinks.previous
          updatedPagination.totalPages = paginationMeta.totalPages
          updatedPagination.totalItems = paginationMeta.totalItems

          dispatch(
            MeasureActionTypes.UPDATE_MEASURE_PAGINATION,
            updatedPagination
          )

          const measures = data.map((measureApiData) => {
            return createMeasureOverviewItem(measureApiData)
          })

          commit(MeasureMutationTypes.SET_MEASURES_OVERVIEW, measures)
          return true
        })
        .catch((e: Error) => {
          console.log(e)
        })
    })
  },
  async [MeasureActionTypes.GET_MEASURE]({ commit }, id: string) {
    return new Promise((resolve, reject) =>
      MeasureDataService.getMeasure(id)
        .then((response: ResponseData) => {
          const item: any = response.data.data
          // console.log("item", item)
          commit(
            MeasureMutationTypes.SET_MEASURE_DETAIL,
            createMeasureDetailItem(item)
          )
          resolve(true)
        })
        .catch((e: Error) => {
          console.log(e)
          reject(false)
        })
    )
  },
  [MeasureActionTypes.UPDATE_SEARCH_VALUE]({ commit }, value: string) {
    commit(MeasureMutationTypes.SET_SEARCH_VALUE, value)
    return true
  },
  [MeasureActionTypes.UPDATE_MEASURE_PAGINATION](
    { commit },
    paginationMeta: Pagination
  ) {
    commit(MeasureMutationTypes.SET_PAGINATION_DATA, paginationMeta)

    return true
  },
}
