import { GetterTree } from "vuex"
import { RootState } from "@/store"
import { State } from "./state"
import { IUserProfile } from "@/types"

export type Getters = {
  getUserProfile(state: State): IUserProfile | null
}

export const getters: GetterTree<State, RootState> & Getters = {
  getUserProfile: (state) => state.userProfile,
}
