import { IDate, IDateRangeInterval, ILabel } from "@/types"
import momentImport from "moment"

export abstract class MeasurementFormatter {
  static formatArea(value: number, unit: string): string {
    return `${value.toLocaleString("de-DE")} ${unit}`
  }
  static formatRange(
    range: { from?: number; to?: number },
    unit: string
  ): string {
    if (range.from && range.to) {
      return (
        this.formatArea(range.from, unit) +
        " - " +
        this.formatArea(range.to, unit)
      )
    }

    if (range.from && !range.to) {
      return "ab " + this.formatArea(range.from, unit)
    }

    if (!range.from && range.to) {
      return "bis " + this.formatArea(range.to, unit)
    }

    return ""
  }
}

export abstract class BooleanFormatter {
  static format(value: boolean): string {
    return value ? "Ja" : "Nein"
  }
}

export abstract class DurationFormatter {
  static format(value: number, suffix: string): string {
    const suffixToSet = value > 1 || value == 0 ? suffix : suffix.slice(0, -1)
    return `${value} ${suffixToSet}`
  }
}

export abstract class CurrencyFormatter {
  static format(value: number, unit?: string): string {
    const u = unit || "€"
    return `${value.toLocaleString("de-DE", { minimumFractionDigits: 2 })} ${u}`
  }
}
export abstract class ArrayFormatter {
  static format(array: Array<ILabel>, separator?: string): string {
    let result = ""
    const s = separator || " "
    array.forEach((el) => {
      result += el.label + s
    })
    return result
  }
}

export abstract class URLFormatter {
  static encodeStringForURL(str: string): string {
    str = encodeURIComponent(str).replace(/-/g, "")
    return str
  }
}

export abstract class DateFormatter {
  static getDateRangeFormatted(
    start: string,
    end: string,
    format?: string
  ): string {
    const f = format || "DD. MMMM"
    const moment = momentImport
    moment.locale("de")
    if (
      (end === "0000-00-00 00:00:00" && start === "0000-00-00 00:00:00") ||
      (end === null && start === null)
    ) {
      return "–"
    }
    if (end === "0000-00-00 00:00:00" || end === null) {
      return "ab " + moment(start).format(f)
    }

    if (start === "0000-00-00 00:00:00" || start === null) {
      return "bis " + moment(end).format(f)
    }

    return moment(start).format(f) + " - " + moment(end).format(f)
  }

  static getDateItemFormatted(
    item: IDate,
    format?: string,
    isInterval?: boolean
  ): IDateRangeInterval {
    const formattedItem: IDateRangeInterval = {
      range: "",
    }

    const f = format || "DD. MMMM"
    const moment = momentImport
    moment.locale("de")
    if (!item.until) {
      formattedItem.range += "ab " + moment(item.from).format(f)
    }

    if (!item.from) {
      formattedItem.range += "bis " + moment(item.until).format(f)
    }

    if (item.from && item.until) {
      formattedItem.range +=
        moment(item.from).format(f) + " - " + moment(item.until).format(f)
    }

    if (item.interval) {
      const intervalValue = isInterval
        ? this.getFormattedIntervalValue(item.interval.value)
        : item.interval.label
      formattedItem.range += " | " + intervalValue
    }

    formattedItem.description = item.description
    return formattedItem
  }

  static getFormattedIntervalValue(value: number | undefined): string {
    if (value === 1) {
      return "jährlich"
    }

    return `alle ${value} Jahre`
  }
}
