/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTree, ActionContext } from "vuex"
import { RootState } from "@/store"
import { State } from "./state"
import { Mutations } from "./mutations"
import { FilterMutationTypes } from "./mutation-types"
import { FilterActionTypes } from "./action-types"
import { ResponseData } from "@/types/ResponseData"
import { MeasureDataService } from "@/api/services"
import { ICheckboxFilterItem, MeasureOverviewFilter, Pagination } from "@/types"
import { ICheckboxFilter } from "@/types/filter/ICheckboxFilter"
import { MeasureActionTypes } from "../measure/action-types"
import { UserActionTypes } from "../user/action-types"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createCheckboxFilter(filterData: any): ICheckboxFilter {
  // console.log("filterData", filterData)
  return {
    type: "ICheckboxFilter",
    label: filterData.label,
    internalIdentifier: filterData.internalIdentifier,
    items: filterData.items.map((el: any) => {
      return {
        id: el.id,
        label: el.label,
        isActive: false,
      }
    }),
  }
}

export function createStatesFilter(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  filterData: any,
  actualFilter: ICheckboxFilter
): ICheckboxFilter {
  return {
    type: "ICheckboxFilter",
    label: filterData.label,
    internalIdentifier: filterData.internalIdentifier,
    items: filterData.items.map((el: any) => {
      return {
        id: el.id,
        label: el.label,
        code: el.code,
        icon: el.icon,
        measuresExists: el.measuresExists,
        isActive:
          actualFilter?.items?.find((state: ICheckboxFilterItem) => {
            return state.id === el.id
          })?.isActive || false,
      }
    }),
  }
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, "commit">

export interface Actions {
  [FilterActionTypes.GET_MEASURE_FILTER]({
    commit,
  }: AugmentedActionContext): Promise<boolean>
  [FilterActionTypes.UPDATE_FILTER_ITEM](
    { commit }: AugmentedActionContext,
    payload: {
      item: { id: string; isActive: boolean }
      filter: string
      updateApiFilter?: boolean
    }
  ): boolean
  [FilterActionTypes.UPDATE_FILTER_ITEMS](
    { commit }: AugmentedActionContext,
    payload: { items: [{ id: string; isActive: boolean }]; filter: string }
  ): boolean
  [FilterActionTypes.UPDATE_TEMPORALLY_FILTER](
    { commit }: AugmentedActionContext,
    payload: { filterValue: { from: string; to: string } }
  ): boolean
  [FilterActionTypes.UPDATE_WIDTH_FILTER](
    { commit }: AugmentedActionContext,
    payload: { filterValue: { min: number; max: number } }
  ): boolean
  [FilterActionTypes.UPDATE_HECTARE_FILTER](
    { commit }: AugmentedActionContext,
    payload: { filterValue: { min: number; max: number } }
  ): boolean
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [FilterActionTypes.GET_MEASURE_FILTER]({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) =>
      MeasureDataService.getFilter(getters.getApiStatesFilterParams)
        .then((response: ResponseData) => {
          const measureOverviewFilter = new MeasureOverviewFilter(
            createCheckboxFilter(response.data.supportProgramsFilter),
            {
              type: "ITemporallyFilter",
              internalIdentifier: "temporallyFilter",
              label: "Förderperiode",
              from: "",
              to: "",
            },
            createStatesFilter(
              response.data.statesFilter,
              getters.getFilterById("statesFilter")
            ),
            createCheckboxFilter(response.data.measureTypesFilter),
            // createCheckboxFilter(response.data.locationsFilter),
            createCheckboxFilter(response.data.targetAreaFilter),
            createCheckboxFilter(response.data.formsFilter),
            {
              type: "IDimensionsFilter",
              internalIdentifier: "dimensionsFilter",
              label: "Maße",
              widthFilter: {
                type: "IWidthFilter",
                internalIdentifier: "widthFilter",
                label: "Meter",
                min: null,
                max: null,
              },
              hectareFilter: {
                type: "IHectareFilter",
                internalIdentifier: "hectareFilter",
                label: "Hektar",
                min: null,
                max: null,
              },
            },
            // createCheckboxFilter(response.data.measureBonusFilter),
            createCheckboxFilter(response.data.commitmentTimesFilter),
            createCheckboxFilter(response.data.sceneFilter)
          )

          commit(FilterMutationTypes.SET_MEASURE_FILTER, measureOverviewFilter)
          resolve(true)
        })
        .catch((e: Error) => {
          console.log(e)
          reject(e)
        })
    )
  },
  [FilterActionTypes.UPDATE_FILTER_ITEM](
    { commit, getters, dispatch },
    payload: {
      item: { id: string; isActive: boolean }
      filter: string
      updateApiFilter?: boolean
    }
  ) {
    commit(FilterMutationTypes.SET_FILTER_ITEM, payload)
    const paginationSetup = getters.getMeasurePagination
    dispatch(
      MeasureActionTypes.UPDATE_MEASURE_PAGINATION,
      new Pagination(1, paginationSetup.limit)
    )
    dispatch(MeasureActionTypes.GET_MEASURES_OVERVIEW)
    if (payload.updateApiFilter) {
      dispatch(FilterActionTypes.GET_MEASURE_FILTER)
    }
    return true
  },
  [FilterActionTypes.UPDATE_FILTER_ITEMS](
    { commit, getters, dispatch },
    payload: { items: [{ id: string; isActive: boolean }]; filter: string }
  ) {
    commit(FilterMutationTypes.SET_FILTER_ITEMS, payload)

    const paginationSetup = getters.getMeasurePagination
    dispatch(
      MeasureActionTypes.UPDATE_MEASURE_PAGINATION,
      new Pagination(1, paginationSetup.limit)
    )
    dispatch(MeasureActionTypes.GET_MEASURES_OVERVIEW)
    dispatch(FilterActionTypes.GET_MEASURE_FILTER)
    dispatch(UserActionTypes.SAVE_USER_FILTER, payload)
    return true
  },
  [FilterActionTypes.UPDATE_TEMPORALLY_FILTER](
    { commit, dispatch },
    payload: { filterValue: { from: string; to: string } }
  ) {
    commit(FilterMutationTypes.SET_TEMPORALLY_FILTER, payload)
    dispatch(MeasureActionTypes.GET_MEASURES_OVERVIEW)
    return true
  },
  [FilterActionTypes.UPDATE_WIDTH_FILTER](
    { commit, dispatch },
    payload: { filterValue: { min: number; max: number } }
  ) {
    commit(FilterMutationTypes.SET_WIDTH_FILTER, payload)
    dispatch(MeasureActionTypes.GET_MEASURES_OVERVIEW)
    return true
  },
  [FilterActionTypes.UPDATE_HECTARE_FILTER](
    { commit, dispatch },
    payload: { filterValue: { min: number; max: number } }
  ) {
    commit(FilterMutationTypes.SET_HECTARE_FILTER, payload)
    dispatch(MeasureActionTypes.GET_MEASURES_OVERVIEW)
    return true
  },
}
