/* eslint-disable @typescript-eslint/no-explicit-any */
import { createLabel } from "../common/ILabel"
import { ISupportProgramm } from "../supportProgramm/ISupportProgramm"
import { IApplicationTimeRange } from "./IApplicationTimeRange"
import { IAreaLocationRestriction } from "./IAreaLocationRestriction"
import { ICombinable } from "./ICombinable"
import { IDate, createDateRangeItem } from "../common/IDate"
import { IResource } from "../common/IResource"
import { IDocumentation } from "./IDocumentation"
import { IDriveOn } from "./IDriveOn"
import { ILivestock } from "./ILivestock"
import { IFederalState } from "./IFederalState"
import { IFixedArea } from "./IFixedArea"
import { IForm } from "./IForm"
import { IGreening } from "./IGreening"
import { IGreeningReduction } from "./IGreeningReduction"
import { IMahdDates } from "./IMahdDates"
import { IManagementRest } from "./IManagementRest"
import { IMeasureBonus } from "./IMeasureBonus"
import { IMeasureGrazingDate } from "./IMeasureGrazingDate"
import { IMeasureType } from "./IMeasureType"
import { IScenerySpartial } from "./IScenerySpartial"
import { ISceneryTargetSpecies } from "./ISceneryTargetSpecies"
import { ISowings } from "./ISowings"
import { ITarget } from "./ITarget"
import { ITargetArea } from "./ITargetArea"
import { ILabel } from "@/types"
import { IUseOfVegetationDates } from "./IUseOfVegetationDates"
import { IValidityPeriodRange } from "./IValidityPeriodRange"
import { ICommitmentTime } from ".."

export interface IMeasureDetail {
  id: string
  code: string
  measureCode: string
  label: string
  titleImage: string
  titleImageCopyright: string
  description: string
  measureType: IMeasureType
  federalState: IFederalState
  validityPeriodRange: IValidityPeriodRange
  target: ITarget[]
  ecologicalBenefit: ILabel[]
  fertilizationTypeOfRestriction: ILabel[]
  fertilizationType: ILabel[]
  measureBonus: IMeasureBonus[]
  minimumAmount: number
  maximumAmount: number
  greening: IGreening[]
  greeningReduction: IGreeningReduction[]
  combinable: boolean
  combinables: ICombinable[]
  optionalAdditionalServices: ICombinable[]
  eligibilityCriteria: string
  scenery: ILabel[]
  scenerySpartial: IScenerySpartial[]
  sceneryTargetSpecies: ISceneryTargetSpecies[]
  targetArea: ITargetArea[]
  location: IAreaLocationRestriction[]
  locationInfo: string
  fixedArea: IFixedArea
  fixedAreaInfo: string
  form: IForm[]
  widthMin: number
  widthMax: number
  hectareMin: number
  hectareMax: number
  sizeInformation: string
  locationRecommendation: string
  selfGreening: boolean
  sowings: ISowings[]
  seedMix: string
  seedMix_none_nc: string
  seedMix_nc: string
  sceneryInfo: string
  // seedMixUrl: string
  seedMixResource: IResource[]
  noteMeasureConception: string
  fertilization: string
  fertilizationInfo: string
  pesticidesUsage: ILabel[]
  driveOn: IDriveOn
  tillage: ILabel[]
  livestock: ILivestock[]
  soilCultivationForReseedings: IDate[]
  managementRest: IManagementRest
  maintenanceRequirements: string
  mahdDates: IMahdDates[]
  maintenanceMeasures: string
  furtherRequirements: string
  managementNotes: string
  grazing: IMeasureGrazingDate[]
  useOfVegetationDates: IUseOfVegetationDates[]
  exceptions: string
  documentation: IDocumentation[]
  additionalInformation: string
  additionalDocuments: string
  contacts: string
  additionalService: boolean
  additionalServiceLabel: string

  applicationTimeRange: IApplicationTimeRange

  commitmentTime: ICommitmentTime
  commitmentTimeAdditionalInfo: string

  supportProgramm: ISupportProgramm
}

export function getPlaceholder(): string {
  return "-"
}

export function createMeasureDetailItem(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  measureApiData: any
): IMeasureDetail {
  const measure: IMeasureDetail = {
    id: measureApiData.id,
    code: measureApiData.code || getPlaceholder(),
    measureCode: measureApiData.measureCode || getPlaceholder(),
    label: measureApiData.label,
    titleImage: measureApiData.titleImage,
    titleImageCopyright: measureApiData.titleImageCopyright,
    description: measureApiData.description,
    measureType: {
      id: measureApiData.measureType.id,
      // label: apiData.label || getPlaceholder(),
      label: measureApiData.measureType.label,
      sort: measureApiData.measureType.sort,
      color: measureApiData.measureType.color,
    },
    // createLabel(measureApiData.measureType),
    federalState: {
      id: measureApiData.federalState.id,
      label: measureApiData.federalState.label,
      code: measureApiData.federalState.code,
      rank: measureApiData.federalState.rank,
    },
    validityPeriodRange: {
      validityPeriodStart:
        measureApiData.validityPeriodRange.validityPeriodStart ||
        getPlaceholder(),
      validityPeriodEnd:
        measureApiData.validityPeriodRange.validityPeriodEnd ||
        getPlaceholder(),
    },
    target: measureApiData.target.map((el: any) => {
      return createLabel(el)
    }),
    ecologicalBenefit: measureApiData.ecologicalBenefitMulti.map((el: any) => {
      return createLabel(el)
    }),
    fertilizationType: measureApiData.fertilizationType.map((el: any) => {
      return createLabel(el)
    }),
    fertilizationTypeOfRestriction:
      measureApiData.fertilizationTypeOfRestriction.map((el: any) => {
        return createLabel(el)
      }),
    fertilizationInfo: measureApiData.fertilizationInfo,
    additionalService: measureApiData.additionalService,
    additionalServiceLabel: measureApiData.additionalServiceObject.label,
    measureBonus: measureApiData.measureBonus.map((el: any) => {
      return {
        id: el.id,
        sponsorshipSupportAmountFrom: el.sponsorshipSupportAmountFrom,
        sponsorshipSupportAmountTo: el.sponsorshipSupportAmountTo,
        unit: el.unit,
        description: el.description,
      }
    }),
    minimumAmount: measureApiData.minimumAmount,
    maximumAmount: measureApiData.maximumAmount || 0,
    greening: measureApiData.greening.map((el: any) => {
      return {
        id: el.id,
        greeningAllowed: el.greeningAllowed,
        greeningFactor: el.greeningFactor || getPlaceholder(),
        greeningFactorDescription:
          el.greeningFactorDescription || getPlaceholder(),
      }
    }),
    greeningReduction: measureApiData.greeningReduction.map((el: any) => {
      return {
        id: el.id,
        greeningReduction: el.greeningReduction || getPlaceholder(),
        greeningReductionDescription:
          el.greeningReductionDescription || getPlaceholder(),
      }
    }),
    combinable: measureApiData.combinable,
    combinables: measureApiData.combinables.map((el: any) => {
      return {
        measure: {
          id: el.measure.id,
          code: el.measure.code || getPlaceholder(),
          label: el.measure.label || getPlaceholder(),
          titleImage: el.measure.titleImage,
          titleImageCopyright: el.measure.titleImageCopyright,
        },
        type: {
          id: el.type.id,
          label: el.type.label,
        },
      }
    }),
    optionalAdditionalServices: measureApiData.optionalAdditionalServices.map(
      (el: any) => {
        return {
          measure: {
            id: el.measure.id,
            code: el.measure.code || getPlaceholder(),
            label: el.measure.label || getPlaceholder(),
            titleImage: el.measure.titleImage,
            titleImageCopyright: el.measure.titleImageCopyright,
          },
          type: {
            id: el.type.id,
            label: el.type.label,
          },
        }
      }
    ),
    eligibilityCriteria: measureApiData.eligibilityCriteria || getPlaceholder(),
    scenery: measureApiData.scenery.map((el: any) => {
      return createLabel(el)
    }),
    scenerySpartial: measureApiData.scenerySpartial.map((el: any) => {
      return {
        id: el.id,
        label: el.label || getPlaceholder(),
      }
    }),
    sceneryTargetSpecies: measureApiData.sceneryTargetSpecies.map((el: any) => {
      return createLabel(el)
    }),
    targetArea: measureApiData.targetArea.map((el: any) => {
      return createLabel(el)
    }),
    location: measureApiData.location.map((el: any) => {
      return createLabel(el)
    }),
    locationInfo: measureApiData.locationInfo || getPlaceholder(),
    fixedArea: createLabel(measureApiData.fixedArea),
    fixedAreaInfo: measureApiData.fixedAreaInfo || getPlaceholder(),
    form: measureApiData.form.map((el: any) => {
      return createLabel(el)
    }),
    widthMin: measureApiData.widthMin,
    widthMax: measureApiData.widthMax,
    hectareMin: measureApiData.hectareMin,
    hectareMax: measureApiData.hectareMax,
    sizeInformation: measureApiData.sizeInformation || getPlaceholder(),
    sceneryInfo: measureApiData.sceneryInfo || getPlaceholder(),
    locationRecommendation:
      measureApiData.locationRecommendation || getPlaceholder(),
    selfGreening: measureApiData.selfGreening,
    sowings: measureApiData.sowings.map((el: any) => {
      return createDateRangeItem(el)
    }),
    seedMix: measureApiData.seedMix || getPlaceholder(),
    seedMix_none_nc: measureApiData.seedMix_none_nc || getPlaceholder(),
    seedMix_nc: measureApiData.seedMix_nc || getPlaceholder(),
    // seedMixUrl: measureApiData.seedMixUrl,
    seedMixResource: measureApiData.seedMixResource,
    noteMeasureConception:
      measureApiData.noteMeasureConception || getPlaceholder(),
    fertilization: measureApiData.fertilization || getPlaceholder(),
    pesticidesUsage: measureApiData.pesticidesUsage.map((el: any) => {
      return createLabel(el)
    }),

    applicationTimeRange: {
      applicationTimeStartBase: new Date(
        measureApiData.applicationTimeRange.applicationTimeStartBase
      ),
      applicationTimeEndBase: new Date(
        measureApiData.applicationTimeRange.applicationTimeEndBase
      ),
    },
    driveOn: {
      id: measureApiData.driveOn.id,
      label: measureApiData.driveOn.label || getPlaceholder(),
      sort: measureApiData.driveOn.sort,
    },
    tillage: measureApiData.tillage.map((el: any) => {
      return createLabel(el)
    }),
    livestock: measureApiData.livestock.map((el: any) => {
      return {
        densityMin: el.densityMin,
        densityMax: el.densityMax,
        unit: el.unit,
        description: el.description,
      }
    }),
    soilCultivationForReseedings:
      measureApiData.soilCultivationForReseedings.map((el: any) => {
        return createDateRangeItem(el)
      }),
    managementRest: createDateRangeItem(measureApiData.managementRest),
    mahdDates: measureApiData.mahdDates.map((el: any) => {
      return createDateRangeItem(el)
    }),
    maintenanceRequirements:
      measureApiData.maintenanceRequirements || getPlaceholder(),
    maintenanceMeasures: measureApiData.maintenanceMeasures || getPlaceholder(),
    furtherRequirements: measureApiData.furtherRequirements || getPlaceholder(),
    managementNotes: measureApiData.managementNotes || getPlaceholder(),
    grazing: measureApiData.grazing.map((el: any) => {
      return createDateRangeItem(el)
    }),
    useOfVegetationDates: measureApiData.useOfVegetationDates.map((el: any) => {
      return createDateRangeItem(el)
    }),
    exceptions: measureApiData.exceptions || getPlaceholder(),
    documentation: measureApiData.documentation.map((el: any) => {
      return {
        id: el.id,
        title: el.title,
        date: createDateRangeItem(el),
        interval: el.interval.label,
        proofs: el.proofs,
        targets: el.target,
        from_description: el.from_description,
        until_description: el.until_description,
        description: el.description || getPlaceholder(),
      }
    }),
    additionalInformation:
      measureApiData.additionalInformation || getPlaceholder(),
    additionalDocuments: measureApiData.additionalDocuments || getPlaceholder(),
    contacts: measureApiData.contacts || getPlaceholder(),

    commitmentTime:
      {
        id: measureApiData.commitmentTime.id,
        label: measureApiData.commitmentTime.label,
        value: measureApiData.commitmentTime.value,
      } || getPlaceholder(),

    commitmentTimeAdditionalInfo: measureApiData.commitmentTimeAdditionalInfo,

    supportProgramm: {
      id: measureApiData.supportProgramm.id,
      authorityCoordination:
        measureApiData.supportProgramm.authorityCoordination,
      applicationProcess: measureApiData.supportProgramm.applicationProcess,
      applicationProgramResource:
        measureApiData.supportProgramm.applicationProgramResource.map(
          (el: any) => {
            return {
              id: el.id,
              internalName: el.internalName,
              label: el.label,
              link: el.link,
              type: el.type,
            }
          }
        ),
      applicationTimeStartBase:
        measureApiData.supportProgramm.applicationTimeStartBase,
      applicationTimeEndBase:
        measureApiData.supportProgramm.applicationTimeEndBase,
      applicationTimeStartPayout:
        measureApiData.supportProgramm.applicationTimeStartPayout,
      applicationTimeEndPayout:
        measureApiData.supportProgramm.applicationTimeEndPayout,
      applicationTimeStartFollowup:
        measureApiData.supportProgramm.applicationTimeStartFollowup,
      applicationTimeEndFollowup:
        measureApiData.supportProgramm.applicationTimeEndFollowup,
      // commitmentTime: {
      //   id: measureApiData.supportProgramm.commitmentTime.id,
      //   label: measureApiData.supportProgramm.commitmentTime.label,
      //   value: measureApiData.supportProgramm.commitmentTime.value,
      // },
      // commitmentTimeAdditionalInfo:
      //   measureApiData.supportProgramm.commitmentTimeAdditionalInfo,
      label: measureApiData.supportProgramm.label,
      grantingAuthority: measureApiData.supportProgramm.grantingAuthority,
      contact: measureApiData.supportProgramm.contact,
      disclosureObligation: measureApiData.supportProgramm.disclosureObligation,
      disclosureObligationAdditionalInfo:
        measureApiData.supportProgramm.disclosureObligationAdditionalInfo,
      sceneryInfoSource: measureApiData.supportProgramm.sceneryInfoSource.map(
        (el: any) => {
          return {
            id: el.id,
            internalName: el.internalName,
            label: el.label,
            link: el.link,
            type: el.type,
          }
        }
      ),
      applicationDeploymentSourceInfo:
        measureApiData.supportProgramm.applicationDeploymentSourceInfo,
      // applicationDeploymentSourceUrl:
      //   measureApiData.supportProgramm.applicationDeploymentSourceUrl,
      applicationDeploymentSource:
        measureApiData.supportProgramm.applicationDeploymentSource.map(
          (el: any) => {
            return {
              id: el.id,
              internalName: el.internalName,
              label: el.label,
              link: el.link,
              type: el.type,
            }
          }
        ),
      directiveResource: measureApiData.supportProgramm.directiveResource.map(
        (el: any) => {
          return {
            id: el.id,
            internalName: el.internalName,
            label: el.label,
            link: el.link,
            type: el.type,
          }
        }
      ),
      informationSheetSource:
        measureApiData.supportProgramm.informationSheetSource.map((el: any) => {
          return {
            id: el.id,
            internalName: el.internalName,
            label: el.label,
            link: el.link,
            type: el.type,
          }
        }),
      responsibilityVOK: measureApiData.supportProgramm.responsibilityVOK,
      adviceOffers: measureApiData.supportProgramm.adviceOffers,
    },
  }
  return measure
}
