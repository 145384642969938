import { Pagination, IMeasureOverview, IMeasureDetail } from "@/types"

export type State = {
  measureOverview: IMeasureOverview[]
  measureDetail: IMeasureDetail | null
  pagination: Pagination | null
  searchValue: string | null
}
export const state: State = {
  measureOverview: [] as Array<IMeasureOverview>,
  measureDetail: null,
  pagination: null,
  searchValue: null,
}
