export interface ILivestock {
  densityMin: number
  densityMax: number
  unit: string
  description: string
}

export function getLivestockOutput(item: ILivestock): string {
  let res = ""

  if (item.densityMin && item.densityMax) {
    res += item.densityMin + " - " + item.densityMax
  }

  if (item.densityMin && !item.densityMax) {
    res += "ab " + item.densityMin
  }

  if (!item.densityMin && item.densityMax) {
    res += "bis " + item.densityMax
  }

  if (item.unit) {
    res += " " + item.unit
  }

  return res
}
