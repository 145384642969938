
import { MeasureActionTypes } from "@/store/modules/measure/action-types"
import { Pagination } from "@/types"
import { computed, defineComponent } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useStore } from "vuex"

export default defineComponent({
  name: "SearchInput",
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const paginationSetup = computed(() => store.getters.getMeasurePagination)
    const searchValue = computed({
      get: () => store.getters.getMeasureSearchValue,
      set: (value) => {
        store.dispatch(MeasureActionTypes.UPDATE_SEARCH_VALUE, value)
      },
    })

    const performSearch = () => {
      store.dispatch(
        MeasureActionTypes.UPDATE_MEASURE_PAGINATION,
        new Pagination(1, paginationSetup.value.limit)
      )

      store.dispatch(MeasureActionTypes.GET_MEASURES_OVERVIEW)
    }

    return {
      searchValue,
      onKeyup: (value: KeyboardEvent) => {
        if (value.key === "Enter") {
          if (route.name !== "MeasuresOverview") {
            router.push({ name: "MeasuresOverview" })
          }

          performSearch()
        }
      },
      performSearch: performSearch,
      resetSearchValue: () => {
        store.dispatch(MeasureActionTypes.UPDATE_SEARCH_VALUE, "")
        performSearch()
      },
    }
  },
})
