import axios, { AxiosInstance } from "axios"

function getApiUrl(): string {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const urlMap: any = {
    localhost: "https://api-dev.pilotnatapp.de/rest",
    // localhost: "https://api-dev.pilotnatapp.de/rest",
    "infothek-dev.pilotnatapp.de": "https://api-dev.pilotnatapp.de/rest",
    "infothek-dev.pilotnatapp.de": "https://api-dev.pilotnatapp.de/rest",
    dokuTool: "https://dokutool.pilotnatapp.de/module/measure/",
  }

  return urlMap[window.location.hostname]
}

// get Path to docutool if we are on extern infothek page
function getDocutoolUrl(): string {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const urlMap: any = {
    localhost: "http://localhost:3000",
    "infothek-dev.pilotnatapp.de": "https://pina-dokutool-dev.goe.flynet.de",
    "infothek-dev.pilotnatapp.de": "https://dokutool.pilotnatapp.de",
  }
  return urlMap[window.location.hostname]
}
const apiClient: AxiosInstance = axios.create({
  baseURL: getApiUrl(),
  headers: {
    "Content-type": "application/json",
  },
})

export { apiClient, getDocutoolUrl }
