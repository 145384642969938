/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import { getPlaceholder } from "@/types"

export interface ILabel {
  id: string
  label: string
  sort?: number
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createLabel(apiData: any): ILabel {
  return {
    id: apiData.id,
    // label: apiData.label || getPlaceholder(),
    label: apiData.label,
    sort: apiData.sort,
  }
}
