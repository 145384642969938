import { GetterTree } from "vuex"
import { RootState } from "@/store"
import { State } from "./state"
import { ICheckboxFilterItem, MeasureOverviewFilter } from "@/types"
import { ICheckboxFilter } from "@/types/filter/ICheckboxFilter"
import { ITemporallyFilter } from "@/types/filter/ITemporallyFilter"
import { IDimensionsFilter } from "@/types/filter/IDimensionsFilter"

export type Getters = {
  getMeasureOverviewFilter(state: State): MeasureOverviewFilter | null
  getApiStatesFilterParams(state: State): string
  getApiFilterParams(state: State): string
  getActiveFilterItemsAmount(state: State): (filterName: string) => number
  getFilterById(
    state: State
  ): (
    filterId: string
  ) => ICheckboxFilter | ITemporallyFilter | IDimensionsFilter | undefined
  getFilterItem(
    state: State
  ): (filterId: string, itemId: string) => ICheckboxFilterItem | undefined
}

export const getters: GetterTree<State, RootState> & Getters = {
  getActiveFilterItemsAmount: (state) => (filterName: string) => {
    const measureOverviewFilter = state.measureOverviewFilter
    if (measureOverviewFilter) {
      let activeItems = []
      const targetFilter = measureOverviewFilter[filterName]
      if (targetFilter?.type === "ICheckboxFilter") {
        activeItems = targetFilter?.items.filter((item) => {
          return item.isActive === true
        })
      }

      if (targetFilter?.type === "ITemporallyFilter") {
        if (targetFilter?.from && targetFilter?.to) {
          activeItems.push(1)
        }
      }

      if (targetFilter?.type === "IDimensionsFilter") {
        const widthFilter = targetFilter?.widthFilter
        const hectareFilter = targetFilter?.hectareFilter
        if (widthFilter.min && widthFilter.max) {
          activeItems.push(1)
        }

        if (hectareFilter.min && hectareFilter.max) {
          activeItems.push(2)
        }
      }

      return activeItems?.length || 0
    }

    return 0
  },
  getApiStatesFilterParams: (state: State) => {
    const stateIds: string[] = []
    const states = state.measureOverviewFilter?.statesFilter?.items?.filter(
      (item: ICheckboxFilterItem) => {
        return item.isActive
      }
    )

    states?.forEach((s: ICheckboxFilterItem) => {
      stateIds.push(s.id)
    })

    if (!stateIds.length) {
      return ""
    }

    return JSON.stringify({ values: stateIds })
  },
  getMeasureOverviewFilter: (state) => state.measureOverviewFilter,
  // eslint-disable-next-line no-shadow
  getApiFilterParams: (state: State) => {
    const result = {
      temporally: {
        from: state.measureOverviewFilter?.temporallyFilter?.from || "",
        to: state.measureOverviewFilter?.temporallyFilter?.to || "",
      },
      states: state.measureOverviewFilter?.statesFilter?.items?.filter(
        (item: ICheckboxFilterItem) => {
          return item.isActive
        }
      ),
      measureTypes:
        state.measureOverviewFilter?.measureTypesFilter?.items?.filter(
          (item: ICheckboxFilterItem) => {
            return item.isActive
          }
        ),
      // locations: state.measureOverviewFilter?.locationsFilter?.items?.filter(
      //   (item: ICheckboxFilterItem) => {
      //     return item.isActive
      //   }
      // ),
      targetArea: state.measureOverviewFilter?.targetAreaFilter?.items?.filter(
        (item: ICheckboxFilterItem) => {
          return item.isActive
        }
      ),
      forms: state.measureOverviewFilter?.formsFilter?.items?.filter(
        (item: ICheckboxFilterItem) => {
          return item.isActive
        }
      ),
      dimensions: {
        width: {
          min: state.measureOverviewFilter?.dimensionsFilter?.widthFilter.min,
          max: state.measureOverviewFilter?.dimensionsFilter?.widthFilter.max,
        },
        hectare: {
          min: state.measureOverviewFilter?.dimensionsFilter?.hectareFilter.min,
          max: state.measureOverviewFilter?.dimensionsFilter?.hectareFilter.max,
        },
      },
      scene: state.measureOverviewFilter?.sceneFilter?.items?.filter(
        (item: ICheckboxFilterItem) => {
          return item.isActive
        }
      ),
      commitmentTimes:
        state.measureOverviewFilter?.commitmentTimesFilter?.items?.filter(
          (item: ICheckboxFilterItem) => {
            return item.isActive
          }
        ),
      supportPrograms:
        state.measureOverviewFilter?.supportProgramsFilter?.items?.filter(
          (item: ICheckboxFilterItem) => {
            return item.isActive
          }
        ),
    }

    return JSON.stringify(result)
  },
  getFilterById: (state: State) => (filterId: string) => {
    const measureOverviewFilter = state.measureOverviewFilter
    if (measureOverviewFilter) {
      return measureOverviewFilter[filterId]
    }

    return undefined
  },
  getFilterItem: (state: State) => (filterId: string, itemId: string) => {
    const measureOverviewFilter = state.measureOverviewFilter
    if (measureOverviewFilter) {
      return (measureOverviewFilter[filterId] as ICheckboxFilter).items.find(
        (item: ICheckboxFilterItem) => item.id === itemId
      )
    }

    return undefined
  },
}
