import { MutationTree } from "vuex"
import { State } from "./state"
import { MeasureMutationTypes } from "./mutation-types"
import { Pagination, IMeasureOverview, IMeasureDetail } from "@/types"

export type Mutations<S = State> = {
  [MeasureMutationTypes.SET_MEASURES_OVERVIEW](
    state: S,
    measures: Array<IMeasureOverview>
  ): void
  [MeasureMutationTypes.SET_MEASURE_DETAIL](
    state: S,
    measure: IMeasureDetail
  ): void
  [MeasureMutationTypes.SET_PAGINATION_DATA](
    state: S,
    paginationData: Pagination
  ): void
  [MeasureMutationTypes.SET_SEARCH_VALUE](state: S, value: string): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MeasureMutationTypes.SET_MEASURES_OVERVIEW](
    state: State,
    measures: IMeasureOverview[]
  ) {
    state.measureOverview = measures
  },
  [MeasureMutationTypes.SET_MEASURE_DETAIL](
    state: State,
    measureDetail: IMeasureDetail
  ) {
    state.measureDetail = measureDetail
  },
  [MeasureMutationTypes.SET_PAGINATION_DATA](
    state: State,
    paginationData: Pagination
  ) {
    state.pagination = paginationData
  },
  [MeasureMutationTypes.SET_SEARCH_VALUE](state: State, value: string) {
    state.searchValue = value
  },
}
