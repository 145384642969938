// eslint-disable-next-line no-shadow
export enum FilterActionTypes {
  GET_MEASURE_FILTER = "GET_MEASURE_FILTER",
  UPDATE_FILTER_ITEM = "UPDATE_FILTER_ITEM",
  UPDATE_FILTER_ITEMS = "UPDATE_FILTER_ITEMS",
  UPDATE_TEMPORALLY_FILTER = "UPDATE_TEMPORALLY_FILTER",
  UPDATE_WIDTH_FILTER = "UPDATE_WIDTH_FILTER",
  UPDATE_HECTARE_FILTER = "UPDATE_HECTARE_FILTER",
  RESET_ALL_FILTER_SETTINGS = "RESET_ALL_FILTER_SETTINGS",
}
