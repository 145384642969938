/* eslint-disable @typescript-eslint/no-explicit-any */
import { createLabel } from "../common/ILabel"
import { ILabel } from "@/types"
import { ISupportProgramm } from "../supportProgramm/ISupportProgramm"
import { IApplicationTimeRange } from "./IApplicationTimeRange"
import { IAreaLocationRestriction } from "./IAreaLocationRestriction"
import { ICommitmentTime } from "./ICommitmentTime"
import { IFederalState } from "./IFederalState"
import { IForm } from "./IForm"
import { IMeasureBonus } from "./IMeasureBonus"
import { IMeasureType } from "./IMeasureType"
import { IScenerySpartial } from "./IScenerySpartial"
import { ISceneryTargetSpecies } from "./ISceneryTargetSpecies"
import { ITargetArea } from "./ITargetArea"
import { IValidityPeriodRange } from "./IValidityPeriodRange"
import { IFixedArea } from "./IFixedArea"
import { ISowings } from "./ISowings"
import { IDate, createDateRangeItem } from "../common/IDate"
import { IManagementRest } from "./IManagementRest"
import { IDriveOn } from "./IDriveOn"
import { ILivestock } from "./ILivestock"
import { IMahdDates } from "./IMahdDates"
import { IMeasureGrazingDate } from "./IMeasureGrazingDate"
import { IUseOfVegetationDates } from "./IUseOfVegetationDates"
import { IGreening } from "./IGreening"
import { IGreeningReduction } from "./IGreeningReduction"

export interface IMeasureOverview {
  id: string
  label: string
  code: string
  federalState: IFederalState
  supportProgramm: ISupportProgramm
  titleImage: string
  titleImageCopyright: string
  measureType: IMeasureType
  additionalService: boolean
  additionalServiceLabel: string
  scenerySpartial: IScenerySpartial[]
  sceneryTargetSpecies: ISceneryTargetSpecies[]
  targetArea: ITargetArea[]
  location: IAreaLocationRestriction[]
  form: IForm[]
  sowings: ISowings[]
  fixedArea: IFixedArea
  widthMin: number
  widthMax: number
  hectareMin: number
  hectareMax: number
  shortDescription: string
  applicationTimeRange: IApplicationTimeRange
  validityPeriodRange: IValidityPeriodRange
  commitmentTime: ICommitmentTime
  measureBonus: IMeasureBonus[]
  fertilizationTypeOfRestriction: ILabel[]
  fertilizationType: ILabel[]
  pesticidesUsage: ILabel[]
  managementRest: IManagementRest
  tillage: ILabel[]
  driveOn: IDriveOn
  scenery: ILabel[]
  livestock: ILivestock[]
  mahdDates: IMahdDates[]
  grazing: IMeasureGrazingDate[]
  soilCultivationForReseedings: IDate[]
  useOfVegetationDates: IUseOfVegetationDates[]
  greening: IGreening[]
  greeningReduction: IGreeningReduction[]
}

function getPlaceholder(): string {
  return "-"
}

export function createMeasureOverviewItem(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  measureApiData: any
): IMeasureOverview {
  const tempMeasureBonus = measureApiData.measureBonus.sort(
    (a: any, b: any) => {
      let aVal = a.sponsorshipSupportAmountFrom
      if (!a.sponsorshipSupportAmountFrom && a.sponsorshipSupportAmountTo) {
        aVal = a.sponsorshipSupportAmountTo
      }
      let bVal = b.sponsorshipSupportAmountFrom
      if (!b.sponsorshipSupportAmountFrom && b.sponsorshipSupportAmountTo) {
        bVal = b.sponsorshipSupportAmountTo
      }
      return aVal > bVal
    }
  )

  const filteredMeasureBonus: any = []
  tempMeasureBonus.forEach((el: any) => {
    const inArray = filteredMeasureBonus.find((item: any) => {
      return item.unit === el.unit
    })

    if (inArray === undefined) {
      filteredMeasureBonus.push(el)
    }
  })

  return {
    id: measureApiData.id,
    label: measureApiData.label,
    code: measureApiData.code,
    supportProgramm: {
      id: measureApiData.supportProgramm.id,
      label: measureApiData.supportProgramm.label,
    },
    federalState: {
      id: measureApiData.federalState.id,
      label: measureApiData.federalState.label,
      code: measureApiData.federalState.code,
      rank: measureApiData.federalState.rank,
    },
    titleImage: measureApiData.titleImage,
    titleImageCopyright: measureApiData.titleImageCopyright,
    measureType: {
      id: measureApiData.measureType.id,
      label: measureApiData.measureType.label,
      sort: measureApiData.measureType.sort,
      color: measureApiData.measureType.color,
    },
    additionalService: measureApiData.additionalService,
    shortDescription: measureApiData.shortDescription,
    additionalServiceLabel: measureApiData.additionalServiceObject.label,
    scenerySpartial: measureApiData.scenerySpartial.map((el: any) => {
      return {
        id: el.id,
        label: el.label,
      }
    }),
    sceneryTargetSpecies: measureApiData.sceneryTargetSpecies.map((el: any) => {
      return {
        id: el.id,
        label: el.label,
        sort: el.sort,
      }
    }),
    targetArea: measureApiData.targetArea.map((el: any) => {
      return {
        id: el.id,
        label: el.label,
        sort: el.sort,
      }
    }),
    location: measureApiData.location.map((el: any) => {
      return {
        id: el.id,
        label: el.label,
        sort: el.sort,
      }
    }),
    form: measureApiData.form.map((el: any) => {
      return {
        id: el.id,
        label: el.label,
        sort: el.sort,
      }
    }),
    livestock: measureApiData.livestock.map((el: any) => {
      return {
        densityMin: el.densityMin,
        densityMax: el.densityMax,
        unit: el.unit,
        description: el.description,
      }
    }),
    soilCultivationForReseedings:
      measureApiData.soilCultivationForReseedings.map((el: any) => {
        return createDateRangeItem(el)
      }),
    useOfVegetationDates: measureApiData.useOfVegetationDates.map((el: any) => {
      return createDateRangeItem(el)
    }),
    driveOn: {
      id: measureApiData.driveOn.id,
      label: measureApiData.driveOn.label || getPlaceholder(),
      sort: measureApiData.driveOn.sort,
    },
    mahdDates: measureApiData.mahdDates.map((el: any) => {
      return createDateRangeItem(el)
    }),
    grazing: measureApiData.grazing.map((el: any) => {
      return createDateRangeItem(el)
    }),
    tillage: measureApiData.tillage.map((el: any) => {
      return createLabel(el)
    }),
    fertilizationType: measureApiData.fertilizationType.map((el: any) => {
      return createLabel(el)
    }),
    fertilizationTypeOfRestriction:
      measureApiData.fertilizationTypeOfRestriction.map((el: any) => {
        return createLabel(el)
      }),
    pesticidesUsage: measureApiData.pesticidesUsage.map((el: any) => {
      return createLabel(el)
    }),
    scenery: measureApiData.scenery.map((el: any) => {
      return createLabel(el)
    }),
    greening: measureApiData.greening.map((el: any) => {
      return {
        id: el.id,
        greeningAllowed: el.greeningAllowed,
        greeningFactor: el.greeningFactor || getPlaceholder(),
        greeningFactorDescription:
          el.greeningFactorDescription || getPlaceholder(),
      }
    }),
    greeningReduction: measureApiData.greeningReduction.map((el: any) => {
      return {
        id: el.id,
        greeningReduction: el.greeningReduction || getPlaceholder(),
        greeningReductionDescription:
          el.greeningReductionDescription || getPlaceholder(),
      }
    }),
    managementRest: createDateRangeItem(measureApiData.managementRest),
    widthMin: measureApiData.widthMin,
    widthMax: measureApiData.widthMax,
    hectareMin: measureApiData.hectareMin,
    hectareMax: measureApiData.hectareMax,
    applicationTimeRange: {
      applicationTimeStartBase: new Date(
        measureApiData.applicationTimeRange.applicationTimeStartBase
      ),
      applicationTimeEndBase: new Date(
        measureApiData.applicationTimeRange.applicationTimeEndBase
      ),
    },
    validityPeriodRange: {
      validityPeriodStart:
        measureApiData.validityPeriodRange.validityPeriodStart,
      validityPeriodEnd: measureApiData.validityPeriodRange.validityPeriodEnd,
    },
    commitmentTime: {
      id: measureApiData.commitmentTime.id,
      label: measureApiData.commitmentTime.label,
      value: measureApiData.commitmentTime.value,
    },
    fixedArea: createLabel(measureApiData.fixedArea),
    measureBonus: filteredMeasureBonus.map((el: any) => {
      return {
        id: el.id,
        sponsorshipSupportAmountFrom: el.sponsorshipSupportAmountFrom,
        sponsorshipSupportAmountTo: el.sponsorshipSupportAmountTo,
        unit: el.unit,
        description: el.description,
      }
    }),
    sowings: measureApiData.sowings.map((el: any) => {
      return createDateRangeItem(el)
    }),
  }
}
