
import { useStore } from "vuex"
import { MeasureActionTypes } from "@/store/modules/measure/action-types"
import { computed, defineComponent, ref } from "vue"
import { Pagination } from "@/types"
import router from "@/router"
import SearchEmptyState from "@/components/search/SearchEmptyState.vue"

export default defineComponent({
  props: {
    toggleOverlay: {
      type: Boolean,
    },
  },
  components: { SearchEmptyState },
  setup(props, { emit }) {
    const store = useStore()
    const toggleOverlayBody = ref(false) // Toggle OverlayBody
    const paginationSetup = computed(() => store.getters.getMeasurePagination)

    // Get Searched Value or String
    const searchValue = computed({
      get: () => store.getters.getMeasureSearchValue,
      set: (value) => {
        store.dispatch(MeasureActionTypes.UPDATE_SEARCH_VALUE, value)
      },
    })

    // Reset Search Value and close the ToggleOverlayBody
    const resetSearchValue = () => {
      toggleOverlayBody.value = false
      searchValue.value = ""
    }
    // To Perform Search
    const performSearch = () => {
      store.dispatch(
        MeasureActionTypes.UPDATE_MEASURE_PAGINATION,
        new Pagination(1, paginationSetup.value.limit)
      )

      store.dispatch(MeasureActionTypes.GET_MEASURES_OVERVIEW)
    }

    // Navigate to Detailed Page
    function navigateTo(id: string, label: string) {
      //Timer to close searchOverlay when a search item get clicked
      setTimeout(function () {
        emit("toggleEvent", false) //Close Overlay
        resetSearchValue() //Close Overlay Body and Clear the Searchbox
      }, 150)
      router.push({
        name: "MeasuresDetail",
        params: { id: id, label: label },
      })
    }
    return {
      searchValue,
      toggleOverlayBody,
      onKeyup: (value: KeyboardEvent) => {
        if (value.key === "Enter") {
          console.log("enter")
        }
      },
      resetSearchValue,
      measures: computed(() => store.getters.getMeasureOverview),
      performSearch: performSearch,
      navigateTo,
    }
  },
})
