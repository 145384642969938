
import { UserActionTypes } from "@/store/modules/user/action-types"
// import { KeycloakProfile } from "keycloak-js"
import { computed, defineComponent, getCurrentInstance, ref } from "vue"
import { useStore } from "vuex"
import SearchInput from "./search/SearchInput.vue"
import SearchInputMobile from "./search/SearchInputMobile.vue" // TODO - Mobile Search

export default defineComponent({
  components: {
    SearchInput,
    SearchInputMobile, // TODO - Mobile Search
  },
  name: "Navigation",
  methods: {
    forceUpdate() {
      this.$forceUpdate()
    },
  },
  setup() {
    const app = getCurrentInstance()
    const keycloak = app?.appContext.config.globalProperties.keycloak
    const toggleOverlay = ref(false)
    const store = useStore()
    // const getInitials = ref({
    //   firstName: store.getters?.getUserProfile?.firstName
    //     .charAt(0)
    //     .toUpperCase(),
    //   lastName: store.getters?.getUserProfile?.lastName.charAt(0).toUpperCase(),
    // })

    // const getInitials: () => ref({
    //   firstName: computed(() => store.getters?.getUserProfile?.firstName)
    //     .value?.charAt(0)
    //     .toUpperCase(),
    //   lastName: computed(() =>
    //     store.getters?.getUserProfile?.lastName.charAt(0).toUpperCase()
    //   ).value?.charAt(0),
    // })
    // keycloak.onAuthError = (e) => {
    //   // Hier scheint es eine Verzögerung zu geben
    //   if (e.error === "login_required") {
    //     keycloak.login()
    //   }
    // }
    const userProfile = computed(() => store.getters.getUserProfile)
    keycloak.onAuthRefreshError = () => {
      console.log("onAuthRefreshError")
    }

    keycloak.onActionUpdate = () => {
      console.log("onActionUpdate")
    }

    keycloak.onAuthLogout = () => {
      console.log("onAuthLogout")
      store.dispatch(UserActionTypes.SET_USER_PROFILE, null)
    }

    keycloak.onAuthRefreshSuccess = () => {
      console.log("onAuthRefreshSuccess")
    }

    let isAuthenticated = ref(false)
    keycloak.onAuthSuccess = () => {
      isAuthenticated.value = true
      keycloak
        .loadUserProfile()
        // .then((result: KeycloakProfile) => {
        .then((result: any) => {
          isAuthenticated = ref(true)
          // TODO dreckiger Hack
          if (userProfile.value === null) {
            // window.location.reload()
          }

          let states
          if (result.attributes?.state) {
            states = result.attributes?.state[0].split(",") || undefined
          }

          store.dispatch(UserActionTypes.SET_USER_PROFILE, {
            id: result.id,
            firstName: result.firstName,
            lastName: result.lastName,
            email: result.email,
            username: result.username,
            states: states,
          })
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: any) => {
          console.log("err", err)
        })
    }

    return {
      toggleOverlay,
      profile: computed(() => store.getters.getUserProfile),
      getInitials: () => {
        const firstName = computed(
          () => store.getters?.getUserProfile?.firstName
        )
          .value?.charAt(0)
          .toUpperCase()
        const lastName = computed(() => store.getters?.getUserProfile?.lastName)
          .value?.charAt(0)
          .toUpperCase()

        return {
          firstName: firstName,
          lastName: lastName,
        }
      },
      userProfile: userProfile,
      keycloak: computed(() => keycloak),
      isAuthenticated: isAuthenticated,
      login: () => keycloak.login(),
      logout: () => {
        store.dispatch(UserActionTypes.SET_USER_PROFILE, null)
        keycloak.logout()
      },
      reg: () => {
        keycloak.register()
      },
      navigateToKeycloakProfile: () => {
        const keycloakProfileUrl =
          keycloak.authServerUrl +
          "/realms/" +
          keycloak.realm +
          "/account/#/personal-info"
        window.open(keycloakProfileUrl.toLowerCase(), "_blank", "noopener")
      },
    }
  },
})
