import { createStore, createLogger } from "vuex"
import createPersistedState from "vuex-persistedstate"

import {
  store as measure,
  MeasureStore,
  State as MeasureState,
} from "@/store/modules/measure"

import {
  store as filter,
  FilterStore,
  State as FilterState,
} from "@/store/modules/filter"

import {
  store as user,
  UserStore,
  State as UserState,
} from "@/store/modules/user"

export type RootState = {
  measure: MeasureState
  filter: FilterState
  user: UserState
}

// export type Store = MeasureStore<Pick<RootState, "measure">> &
//   ApplicationStore<Pick<RootState, "application">>

export type Store = MeasureStore<Pick<RootState, "measure">> &
  FilterStore<Pick<RootState, "filter">> &
  UserStore<Pick<RootState, "user">>

// Plug in logger when in development environment
const debug = process.env.NODE_ENV !== "production"
const plugins = debug ? [createLogger({})] : []

// Plug in session storage based persistence
plugins.push(createPersistedState({ storage: window.sessionStorage }))

export const store = createStore({
  plugins,
  modules: {
    measure,
    filter,
    user,
  },
})

export function useStore(): Store {
  return store as Store
}
